/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Cruise_AvailableDate_available_date_read } from '../models/Cruise_AvailableDate_available_date_read';
import type { Cruise_AvailableLocation_available_boat_group_read } from '../models/Cruise_AvailableLocation_available_boat_group_read';
import type { Cruise_AvailableLocation_available_cruise_read } from '../models/Cruise_AvailableLocation_available_cruise_read';
import type { Cruise_AvailableTimeSlot_available_cruise_read } from '../models/Cruise_AvailableTimeSlot_available_cruise_read';
import type { Cruise_cruise_read } from '../models/Cruise_cruise_read';
import type { Cruise_UnavailableTimeSlotDetails_unavailable_time_slot_details_read } from '../models/Cruise_UnavailableTimeSlotDetails_unavailable_time_slot_details_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CruiseService {

    /**
     * Retrieves the collection of Cruise resources.
     * Retrieves the collection of Cruise resources.
     * @returns Cruise_AvailableLocation_available_cruise_read Cruise collection
     * @throws ApiError
     */
    public static apiAdminavailableCruisesGetCollection({
        type,
        date,
        lengthFrom,
        lengthTo,
        seats,
        excludedReservationId,
    }: {
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Date of cruise
         */
        date: string,
        /**
         * Length Range From (in minutes)
         */
        lengthFrom: number,
        /**
         * Length Range To  (in minutes)
         */
        lengthTo: number,
        /**
         * Number of seats (only for public cruise)
         */
        seats?: number,
        /**
         * Excluded reservation ID (on update)
         */
        excludedReservationId?: number,
    }): CancelablePromise<Array<Cruise_AvailableLocation_available_cruise_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/available-cruises',
            query: {
                'type': type,
                'date': date,
                'lengthFrom': lengthFrom,
                'lengthTo': lengthTo,
                'seats': seats,
                'excludedReservationId': excludedReservationId,
            },
        });
    }

    /**
     * Retrieves the collection of Cruise resources.
     * Retrieves the collection of Cruise resources.
     * @returns Cruise_cruise_read Cruise collection
     * @throws ApiError
     */
    public static apiAdmincruisesGetCollection({
        page = 1,
        pagination,
        type,
        boatBoatGroupLocation,
        boatBoatGroup,
        startAtBefore,
        startAtStrictlyBefore,
        startAtAfter,
        startAtStrictlyAfter,
        endAtBefore,
        endAtStrictlyBefore,
        endAtAfter,
        endAtStrictlyAfter,
        orderStartAt,
        orderEndAt,
        reservationsId,
        reservationsCustomerAddressFirstName,
        reservationsCustomerAddressLastName,
        reservationsCustomerAddressEmail,
        reservationsStatus,
        reservationsPromocodeCode,
        reservationsCreatedAtBefore,
        reservationsCreatedAtStrictlyBefore,
        reservationsCreatedAtAfter,
        reservationsCreatedAtStrictlyAfter,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * Enable or disable pagination
         */
        pagination?: boolean,
        type?: Array<string>,
        boatBoatGroupLocation?: Array<string>,
        boatBoatGroup?: Array<string>,
        startAtBefore?: string,
        startAtStrictlyBefore?: string,
        startAtAfter?: string,
        startAtStrictlyAfter?: string,
        endAtBefore?: string,
        endAtStrictlyBefore?: string,
        endAtAfter?: string,
        endAtStrictlyAfter?: string,
        orderStartAt?: 'asc' | 'desc',
        orderEndAt?: 'asc' | 'desc',
        reservationsId?: Array<number>,
        reservationsCustomerAddressFirstName?: string,
        reservationsCustomerAddressLastName?: string,
        reservationsCustomerAddressEmail?: string,
        reservationsStatus?: Array<string>,
        reservationsPromocodeCode?: Array<string>,
        reservationsCreatedAtBefore?: string,
        reservationsCreatedAtStrictlyBefore?: string,
        reservationsCreatedAtAfter?: string,
        reservationsCreatedAtStrictlyAfter?: string,
    }): CancelablePromise<Array<Cruise_cruise_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/cruises',
            query: {
                'page': page,
                'pagination': pagination,
                'type[]': type,
                'boat.boatGroup.location[]': boatBoatGroupLocation,
                'boat.boatGroup[]': boatBoatGroup,
                'startAt[before]': startAtBefore,
                'startAt[strictly_before]': startAtStrictlyBefore,
                'startAt[after]': startAtAfter,
                'startAt[strictly_after]': startAtStrictlyAfter,
                'endAt[before]': endAtBefore,
                'endAt[strictly_before]': endAtStrictlyBefore,
                'endAt[after]': endAtAfter,
                'endAt[strictly_after]': endAtStrictlyAfter,
                'order[startAt]': orderStartAt,
                'order[endAt]': orderEndAt,
                'reservations.id[]': reservationsId,
                'reservations.customerAddress.firstName': reservationsCustomerAddressFirstName,
                'reservations.customerAddress.lastName': reservationsCustomerAddressLastName,
                'reservations.customerAddress.email': reservationsCustomerAddressEmail,
                'reservations.status[]': reservationsStatus,
                'reservations.promocode.code[]': reservationsPromocodeCode,
                'reservations.createdAt[before]': reservationsCreatedAtBefore,
                'reservations.createdAt[strictly_before]': reservationsCreatedAtStrictlyBefore,
                'reservations.createdAt[after]': reservationsCreatedAtAfter,
                'reservations.createdAt[strictly_after]': reservationsCreatedAtStrictlyAfter,
            },
        });
    }

    /**
     * Retrieves a Cruise resource.
     * Retrieves a Cruise resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdmincruisesIdGet({
        id,
    }: {
        /**
         * Cruise identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/cruises/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves a Cruise resource.
     * Retrieves a Cruise resource.
     * @returns Cruise_UnavailableTimeSlotDetails_unavailable_time_slot_details_read Cruise resource
     * @throws ApiError
     */
    public static apiAdminunavailableTimeSlotDetailsGet({
        type,
        boatGroupId,
        startAt,
        endAt,
        excludedReservationId,
    }: {
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Boat Group ID
         */
        boatGroupId: number,
        /**
         * Cruise Start Time
         */
        startAt: string,
        /**
         * Cruise End Time
         */
        endAt: string,
        /**
         * Excluded reservation ID (on update)
         */
        excludedReservationId?: number,
    }): CancelablePromise<Cruise_UnavailableTimeSlotDetails_unavailable_time_slot_details_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/unavailable-time-slot-details',
            query: {
                'type': type,
                'boatGroupId': boatGroupId,
                'startAt': startAt,
                'endAt': endAt,
                'excludedReservationId': excludedReservationId,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of Cruise resources.
     * Retrieves the collection of Cruise resources.
     * @returns Cruise_AvailableLocation_available_boat_group_read Cruise collection
     * @throws ApiError
     */
    public static apiClientavailableCruisesGetCollection({
        type,
        date,
        lengthFrom,
        lengthTo,
        seats,
        excludedReservationId,
    }: {
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Date of cruise
         */
        date: string,
        /**
         * Length Range From (in minutes)
         */
        lengthFrom: number,
        /**
         * Length Range To  (in minutes)
         */
        lengthTo: number,
        /**
         * Number of seats (only for public cruise)
         */
        seats?: number,
        /**
         * Excluded reservation ID (on update)
         */
        excludedReservationId?: number,
    }): CancelablePromise<Array<Cruise_AvailableLocation_available_boat_group_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/available-cruises',
            query: {
                'type': type,
                'date': date,
                'lengthFrom': lengthFrom,
                'lengthTo': lengthTo,
                'seats': seats,
                'excludedReservationId': excludedReservationId,
            },
        });
    }

    /**
     * Retrieves a Cruise resource.
     * Retrieves a Cruise resource.
     * @returns void
     * @throws ApiError
     */
    public static apiClientcruisesIdGet({
        id,
    }: {
        /**
         * Cruise identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/cruises/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of Cruise resources.
     * Retrieves the collection of Cruise resources.
     * @returns Cruise_AvailableTimeSlot_available_cruise_read Cruise collection
     * @throws ApiError
     */
    public static apiClientgroupAvailableCruisesGetCollection({
        boatGroupId,
        type,
        date,
        lengthFrom,
        lengthTo,
        seats,
        excludedReservationId,
    }: {
        /**
         * Boat Group ID
         */
        boatGroupId: number,
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Date of cruise
         */
        date: string,
        /**
         * Length Range From (in minutes)
         */
        lengthFrom: number,
        /**
         * Length Range To  (in minutes)
         */
        lengthTo: number,
        /**
         * Number of seats (only for public cruise)
         */
        seats?: number,
        /**
         * Excluded reservation ID (on update)
         */
        excludedReservationId?: number,
    }): CancelablePromise<Array<Cruise_AvailableTimeSlot_available_cruise_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/group-available-cruises',
            query: {
                'boatGroupId': boatGroupId,
                'type': type,
                'date': date,
                'lengthFrom': lengthFrom,
                'lengthTo': lengthTo,
                'seats': seats,
                'excludedReservationId': excludedReservationId,
            },
        });
    }

    /**
     * Retrieves the collection of Cruise resources.
     * Retrieves the collection of Cruise resources.
     * @returns Cruise_AvailableDate_available_date_read Cruise collection
     * @throws ApiError
     */
    public static apiClientgroupMonthAvailableDaysGetCollection({
        boatGroupId,
        type,
        monthDate,
    }: {
        /**
         * Boat Group ID
         */
        boatGroupId: number,
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Month date
         */
        monthDate: string,
    }): CancelablePromise<Array<Cruise_AvailableDate_available_date_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/group-month-available-days',
            query: {
                'boatGroupId': boatGroupId,
                'type': type,
                'monthDate': monthDate,
            },
        });
    }

    /**
     * Retrieves the collection of Cruise resources.
     * Retrieves the collection of Cruise resources.
     * @returns Cruise_AvailableDate_available_date_read Cruise collection
     * @throws ApiError
     */
    public static apiClientmonthAvailableDaysGetCollection({
        type,
        monthDate,
    }: {
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Month date
         */
        monthDate: string,
    }): CancelablePromise<Array<Cruise_AvailableDate_available_date_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/month-available-days',
            query: {
                'type': type,
                'monthDate': monthDate,
            },
        });
    }

}
