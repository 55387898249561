/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Boat_jsonld_unavailable_time_slot_details_read } from './Boat_jsonld_unavailable_time_slot_details_read';
import type { BookedReason_jsonld_unavailable_time_slot_details_read } from './BookedReason_jsonld_unavailable_time_slot_details_read';

export type Cruise_UnavailableTimeSlotDetails_jsonld_unavailable_time_slot_details_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Cruise_UnavailableTimeSlotDetails_jsonld_unavailable_time_slot_details_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    hasMaxCruises?: boolean;
    availableBoats?: Array<Boat_jsonld_unavailable_time_slot_details_read>;
    bookedReasons?: Array<BookedReason_jsonld_unavailable_time_slot_details_read>;
};

export namespace Cruise_UnavailableTimeSlotDetails_jsonld_unavailable_time_slot_details_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

