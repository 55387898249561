/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Cruise_jsonld_unavailable_time_slot_details_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Cruise_jsonld_unavailable_time_slot_details_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    isSuperoverride?: boolean;
    startAt?: string;
    endAt?: string;
    readonly type?: string;
    readonly bookedSeats?: number | null;
};

export namespace Cruise_jsonld_unavailable_time_slot_details_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

